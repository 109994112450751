.main-container {
  position: relative;
  min-width: 100%;
}

.ye-main {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  min-width: 100%;
  z-index: 1;
}
.pic-container {
  height: 792px;
}
/* .userPhoto{
    height: 792px;
    
  } */
.userPhoto img {
  position: relative;
  top: 319px;
  height: 330px;
  min-width: 280px;
  background-color: blue;
}

.submissions {
  position: relative;
  top: 100px;
}

.button_slide,
input[type="file"] {
  color: black;
  border: 2px solid #1625f5;
  border-radius: 0px;
  padding: 18px 36px;
  display: inline-block;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #1625f5;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

#outer {
  width: 364px;
  margin: 50px auto 0 auto;
  text-align: center;
}

.slide_left:hover {
  box-shadow: inset 0 0 0 50px #d80286;
}

.slide_down:hover {
  box-shadow: inset 0 100px 0 0 #d80286;
}

input[type="text"] {
  min-height: 100px;
  min-width: 200px;
}

a {
  padding: 20px;
}

#dl {
  position: relative;
  top: 945px;
  left: 240px;
  border: 2px solid black;
  padding: 10px 10px;
  z-index: 1;
}
