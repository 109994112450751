.pic-container {
  position: relative;
}

.text-box {
  position: absolute;
  top: 820px;
  left: 0;
  right: 0;
  height: 60px;
}

.present-text h3 {
  position: absolute;
  top: 400px;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 65%;
  font-family: "Permanent Marker", cursive;
  z-index: 1;
  max-width: 210px;
}

.button_slide,
#submit-btn {
  color: black;
  border: 2px solid rgb(216, 2, 134);
  border-radius: 0px;
  padding: 18px 36px;
  display: inline-block;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #d80286;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.6s;
}

#outer {
  width: 364px;
  margin: 50px auto 0 auto;
  text-align: center;
}

.slide_left:hover {
  box-shadow: inset 0 0 0 50px #d80286;
}

.slide_right:hover {
  box-shadow: inset 400px 0 0 0 #d80286;
}

#enter {
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 14px;
  letter-spacing: 1px;
}
/* 
@media (max-width: 600px) {
  .present-text h3 {
    left: 220px;
    width: 200px;
    
  }
  
  .text-box {
    
    left: 190px;
  }

  #title{
    
    margin-left: 7rem;
    
  }
  
} */
